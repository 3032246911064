import { Chip } from '@mui/material';
import { useTranslation } from 'next-export-i18n';

interface IChipProps {
    status?: string;
}

const getChipColor = (status: string) => {
    switch (status) {
        case 'paid':
        case 'accepted':
        case 'success':
            return { backgroundColor: '#22A958', color: '#fff' };
        case 'partially_paid':
            return { backgroundColor: '#EE7300', color: '#fff' };
        case 'discarded':
        case 'refunded':
            return { backgroundColor: '#FFC21C', color: '#111927' };
        case 'expired':
        case 'failed':
        case 'rejected':
            return { backgroundColor: '#DF1D17', color: '#fff' };
        case 'bill':
        case 'qlub-loyalty':
        case 'qlub-voucher':
        case 'qlub-discount':
            return { backgroundColor: '#7D00D4', color: '#FFFFFF' };
        default:
            return {};
    }
};

const StatusChip = ({ status = 'pending' }: IChipProps) => {
    const { t } = useTranslation('common');

    const getPaymentStatusText = (text?: string) => {
        switch (text) {
            default:
            case 'created':
            case 'pending':
                return t('Created');
            case 'paid':
                return t('Fully paid');
            case 'partially_paid':
                return t('Partially Paid');
            case 'discarded':
                return t('Discarded');
            case 'expired':
                return t('Expired');
            case 'accepted':
            case 'success':
                return t('Successful');
            case 'failed':
                return t('Failed');
            case 'refunded':
                return t('Refunded');
            case 'scheduled':
                return t('Scheduled');
            case 'rejected':
                return t('Rejected');
            case 'qlub-discount':
                return t('Discount');
            case 'qlub-voucher':
                return t('Voucher');
            case 'qlub-loyalty':
                return t('Loyalty');
            case 'bill':
                return t('Bill');
        }
    };

    return (
        <Chip
            label={getPaymentStatusText(status)}
            size="small"
            sx={{
                ...getChipColor(status),
                borderRadius: '0.2rem',
            }}
        />
    );
};

export default StatusChip;
